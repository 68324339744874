import FooterBottomSheet from '@/components/FooterBottomSheet';
import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Button, Dialog, DialogContent, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonalData from './components/PersonalData';
import { MakeOrderRequest } from '@/models/delivery/makeOrder.request';
import { usePages } from '@/contexts/pages';
import CommonValidators from '@/helpers/validators/common.validators';
import ModalLoading from '@/components/ModalLoading';
import { GetCustomerData } from '@/services/delivery/user';
import ModalError from '@/components/ModalError';
import { TransitionProps } from '@mui/material/transitions';
import { useCart } from '@/contexts/delivery/cart';
import { MakePaymentRequest } from '@/models/delivery/makePayment.request';
import { ListTakeAway, PostPayment } from '@/services/delivery/payment';
import CommonMask from '@/helpers/masks';
import ModalCoupon from '@/components/ModalCoupon';
import RegisterAddressPage from './components/RegisterAddress';
import { AddressModel } from '@/models/delivery/address.model';
import DeliveryComp from './components/Delivery';
import ModalSuccess from '@/components/DeliveryPage/ModalSucess';
import PayComp from './components/pay';
import { resetOrder } from '@/redux/reducers/delivery/order';
import { ListOrder, ListSchedules } from '@/services/delivery/order';
import { reset, resetCoupon } from '@/redux/reducers/delivery/payment';
import CommonClears from '@/helpers/clears/common.clear';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentPage() {
	const { cart } = useCart();
	const dispatch = useAppDispatch();
	const { client } = useAppSelector(state => state.authDelivery);
	const { requestError, loading, responsePayment, couponResponse } = useAppSelector(state => state.paymentDelivery);
	const { customer, loadingCustomer, deleteAddress, registerAddress } = useAppSelector(state => state.userDelivery);
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');
	const [method, setMethod] = useState('');
	const [payAtTheRestaurant, setPayAtTheRestaurant] = useState('');
	const { setOpenPayment, openPayment, setOpenOrder } = usePages();
	const [form, setForm] = useState({
		cpf: '',
		name: '',
		phone: '',
	});
	const [error, setError] = useState({
		cpf: '',
		name: '',
		phone: '',
	});
	const [formCard, setFormCard] = useState({
		number: '',
		cvc: '',
		month: '',
		year: '',
		parcelamento: '',
	});
	const [cardError, setCardError] = useState({
		number: '',
		cvc: '',
		month: '',
		year: '',
		parcelamento: '',
	});
	const [openSuccess, setOpenSuccess] = useState(false);
	const [titleSuccess, setTitleSuccess] = useState('');
	const [openCoupon, setOpenCoupon] = useState(false);
	const [openLoading, setOpenLoading] = useState(false);
	const [changeAmount, setChangeAmount] = useState('');
	const [msgLoading, setMsgLoading] = useState('');
	const [page, setPage] = useState(1);
	const [openRegisterAddress, setOpenRegisterAddress] = useState(false);
	const [address, setAddress] = useState(0);
	const [addressTakeAway, setAddressTakeAway] = useState(0);
	const [coupon, setCoupon] = useState('');
	const [editAddress, setEditAddress] = useState<AddressModel>();
	const [scheduleData, setScheduleData] = useState({
		id: '',
		date: '',
	});
	const [shippingTax, setShippingTax] = useState(0);

	useEffect(() => {
		if (openPayment) {
			(async () => {
				await dispatch(ListTakeAway());
			})();
		}
	}, [openPayment]);

	function onDismiss() {
		setCoupon('');
		setMethod('');
		setPayAtTheRestaurant('');
		setShippingTax(0);
		setAddressTakeAway(0);
		setAddress(0);
		setPage(1);
		setOpenPayment(false);
		dispatch(resetOrder());
		dispatch(resetCoupon());
	}

	function handleChange(value: string | number, fieldName: string, typeForm: 'personal' | 'card') {
		if (typeForm === 'personal') {
			setForm({ ...form, [fieldName]: value });
		} else {
			setFormCard({ ...formCard, [fieldName]: value });
		}
	}

	useEffect(() => {
		if (requestError) {
			setOpenLoading(false);
			setSnackMsg(requestError);
			setShowSnack(true);

			if (!couponResponse?.sucesso) {
				setCoupon('');
			}
		}
	}, [requestError]);

	useEffect(() => {
		if (responsePayment) {
			if (responsePayment.chave_pix) {
				onDismiss();
			} else if (responsePayment.retorno) {
				(async () => {
					if (form.cpf) {
						await dispatch(ListOrder(form.cpf));
					} else {
						await dispatch(ListOrder(form.phone));
					}
				})();
				dispatch(reset());
				onDismiss();
				setOpenOrder(true);
			}
		}
	}, [responsePayment]);

	useEffect(() => {
		setOpenLoading(loading);
	}, [loading]);

	async function submitPayment() {
		setMsgLoading('Processando pedido...');
		setOpenLoading(true);

		const formProducts: MakeOrderRequest['produtos'] = [];

		cart.items.forEach((product, index) => {
			formProducts.push({
				produto_id: product.id,
				quantidade: product.qtd,
				observacao: product.observation,
				unidade_medida_id: product.idUnity,
				opcionais: [],
				tipo_desconto: 'P',
				valor_desconto: 0,
			});

			if (product.options) {
				product.options.forEach(option => {
					formProducts[index].opcionais.push({
						id: option.id.toString(),
						quantidade: option.qtd.toString(),
					});
				});
			}
		});

		// const cpfValidator = CommonValidators.isCPFValid(form.cpf);
		// const nameValidator =
		//   method === '2'
		//     ? CommonValidators.isNameValid(form.name)
		//     : form.name.length < 1
		//     ? { status: false, error: 'Nome obrigatório!' }
		//     : { status: true, error: '' };
		// const phoneValidator = CommonValidators.isCellphoneValid(form.phone);
		let validCard = true;
		let errorData = [];

		// if (!cpfValidator.status) {
		//   errorData.push('CPF');
		// }

		// if (!nameValidator.status) {
		//   errorData.push('nome');
		// }

		// if (!phoneValidator.status) {
		//   errorData.push('telefone');
		// }

		if (method === '2') {
			const numberValidator = CommonValidators.isEmptyField(formCard.number, 'número do cartão');
			const monthValidator = CommonValidators.isEmptyField(formCard.month, 'mês');
			const yearValidator = CommonValidators.isEmptyField(formCard.year, 'ano');
			const cvcValidator = CommonValidators.isEmptyField(formCard.cvc, 'cvc');
			const parcelamentoValidator = CommonValidators.isEmptyField(formCard.parcelamento, 'parcelamento');

			setCardError({
				number: numberValidator.error,
				month: monthValidator.error,
				year: yearValidator.error,
				cvc: cvcValidator.error,
				parcelamento: parcelamentoValidator.error,
			});

			if (!numberValidator.status) {
				errorData.push('número');
			}
			if (!monthValidator.status) {
				errorData.push('mês');
			}
			if (!yearValidator.status) {
				errorData.push('ano');
			}
			if (!cvcValidator.status) {
				errorData.push('validade');
			}
			if (!parcelamentoValidator.status) {
				errorData.push('parcelamento');
			}

			if (
				+formCard.year < new Date().getFullYear() ||
				+formCard.year > new Date().getFullYear() + 30 ||
				+formCard.month < 1 ||
				+formCard.month > 12 ||
				!numberValidator.status ||
				!monthValidator.status ||
				!yearValidator.status ||
				!cvcValidator.status
			) {
				validCard = false;
			}
		}

		// setError({
		//   cpf: cpfValidator.error,
		//   name: nameValidator.error,
		//   phone: phoneValidator.error,
		// });

		if (errorData.length > 0) {
			setSnackMsg(`Por favor, insira os dados (${errorData.join(', ')}) corretamente!`);
			setShowSnack(true);
		}

		if (customer?.retorno) {
			let formData: MakePaymentRequest = {
				cliente_id: customer.conteudo.id,
				endereco_id: address,
				retirada_id: addressTakeAway,
				observacao: '',
				total_entrega: shippingTax,
				total_desconto: couponResponse?.conteudo.desconto || 0,
				total_troco: +(CommonClears.clearCurrency(changeAmount) ? CommonClears.clearCurrency(changeAmount, true) : 0),
				tipo_desconto: couponResponse?.conteudo.tipo_desconto || '',
				cupom: coupon,
				data_agendamento: scheduleData.date,
				agendamento_id: scheduleData.id || 0,
				pagamento: [
					{
						id: method || payAtTheRestaurant,
						cpf: form.cpf,
						nome_completo: form.name,
						pagamento_online: !!method,
					},
				],
				produtos: formProducts,
			};

			if (validCard && method === '2') {
				formData.pagamento[0].cvc = formCard.cvc;
				formData.pagamento[0].expiracao = formCard.month + '/' + formCard.year;
				formData.pagamento[0].numero_cartao = formCard.number;
				formData.pagamento[0].parcelas = formCard?.parcelamento ? formCard.parcelamento : '01';
			}
			if (client?.config_cliente?.pixel_facebook) {
				if (window?.fbq && window?.fbq?.version) {
					eval(`fbq('track', 'Purchase', {currency: "BRL", value: ${cart?.tot}});`);
				}
			}
			const test = await dispatch(PostPayment(formData));
		}
	}

	const handleChangeChanges = (changeValue: string) => {
		const realValue = 'R$ ' + CommonMask.currency(CommonClears.clearCurrency(changeValue).toString());
		setChangeAmount(realValue);
	};

	async function getCustomerData(cpf: string, name?: string, phone?: string) {
		return await dispatch(GetCustomerData({ cpf, fone: phone || '', nome: name || '' }));
	}

	async function getCustomerDataFromPhone(phone: string) {
		if (phone) {
			await dispatch(GetCustomerData({ fone: phone, cpf: '', nome: '' }));
		}
	}

	useEffect(() => {
		if (form.cpf) {
			const cpfValidator = CommonValidators.isCPFValid(form.cpf);
			if (cpfValidator.status) {
				getCustomerData(form.cpf);
			}
		}
	}, [form.cpf]);

	useEffect(() => {
		if (!form.cpf) {
			const phoneValidator = CommonValidators.isCellphoneValid(form.phone);
			if (phoneValidator.status) {
				getCustomerDataFromPhone(form.phone);
			}
		}
	}, [form.phone]);

	useEffect(() => {
		if (customer?.conteudo) {
			setForm({
				...form,
				phone: customer.conteudo.fone || form.phone,
				name: customer.conteudo.nome || form.name,
			});
		}
	}, [customer]);

	useEffect(() => {
		if (deleteAddress?.retorno) {
			setOpenLoading(false);
			setTitleSuccess('Endereço excluído com sucesso!');
			setOpenSuccess(true);
			getCustomerData(customer?.conteudo.cpf || form.cpf, form.name, form.phone);
		}
	}, [deleteAddress]);

	useEffect(() => {
		if (registerAddress?.retorno) {
			setTitleSuccess('Endereço salvo com sucesso!');
			setOpenSuccess(true);
		}
	}, [registerAddress]);

	async function submitCustomer() {
		if (form.phone.length != 11 && form.cpf.length != 11) {
			setSnackMsg('CPF ou Telefone inválidos');
			await setOpenLoading(false);
			setShowSnack(true);
			return;
		}
		const requestData = await getCustomerData(form.cpf, form.name, form.phone);
		await dispatch(ListSchedules());
		if (!requestData.payload.retorno) {
			setSnackMsg(requestData.payload.mensagem);
			await setOpenLoading(false);
			setShowSnack(true);
		} else {
			await setOpenLoading(false);
			await setPage(2);
		}
	}

	useEffect(() => {
		if (responsePayment?.retorno) {
			setOpenLoading(false);
		}
	}, [responsePayment]);

	function handleSuccess() {
		setOpenSuccess(false);
	}

	const handleAddressValidation = () => {
		if ((address === 0 && addressTakeAway === 0) || (!client?.config_cliente.disponivel && !scheduleData.id)) {
			if (address === 0 && addressTakeAway === 0) setSnackMsg('Endereço não selecionado corretamente.');
			if (!client?.config_cliente.disponivel && !scheduleData.id) setSnackMsg('Data não selecionada corretamente.');
			setShowSnack(true);
		} else {
			setPage(3);
		}
	};

	const handlePaymentValidation = () => {
		if (!method && !payAtTheRestaurant) {
			setSnackMsg('Método de pagamento não escolhido');
			setShowSnack(true);
		} else {
			submitPayment();
		}
	};

	return (
		<>
			<Dialog fullScreen open={openPayment} onClose={onDismiss} TransitionComponent={Transition} scroll="paper" className="customDialog" disableScrollLock id="paymentDelivery">
				<HeaderBottomSheet title={page !== 1 ? 'Finalizar Pedido' : 'Identifique-se'} onDismiss={onDismiss} />

				<DialogContent>
					{page === 1 && <PersonalData handleChange={handleChange} form={form} error={error} />}

					{page !== 1 && (
						<div className="content">
							<p className="mb-2" style={{ color: 'var(--first-color)' }}>
								Este pedido será entregue a:
							</p>
							<div className="head">
								<div className="flex-1" style={{ color: 'var(--first-color)' }}>
									<p className="font-medium">{form.name}</p>
									<p>{CommonMask.phone(form.phone)}</p>
								</div>
								<Button variant="text" className="editBtn" onClick={() => setPage(1)}>
									Editar
								</Button>
							</div>

							{page === 2 && (
								<DeliveryComp
									address={address}
									addressTakeAway={addressTakeAway}
									setAddress={setAddress}
									setAddressTakeAway={setAddressTakeAway}
									setEditAddress={setEditAddress}
									setOpenRegisterAddress={setOpenRegisterAddress}
									setShowSnack={setShowSnack}
									setSnackMsg={setSnackMsg}
									setOpenLoading={setOpenLoading}
									setMsgLoading={setMsgLoading}
									scheduleData={scheduleData}
									setScheduleData={setScheduleData}
									setShippingTax={setShippingTax}
								/>
							)}

							{page === 3 && (
								<PayComp
									changeAmount={changeAmount}
									handleChangeChanges={handleChangeChanges}
									address={address}
									setMethod={setMethod}
									method={method}
									handleChange={handleChange}
									cardError={cardError}
									formCard={formCard}
									setFormCard={setFormCard}
									setPayAtTheRestaurant={setPayAtTheRestaurant}
									payAtTheRestaurant={payAtTheRestaurant}
								/>
							)}
							<Button variant="text" className="addCoupon" onClick={() => setOpenCoupon(true)}>
								Adicionar um cupom de desconto
							</Button>
						</div>
					)}
				</DialogContent>

				{page === 1 && (
					<FooterBottomSheet
						btnTxt="Avançar"
						tot={cart.tot ? cart.tot : 0}
						qtd={cart.items.length ? cart.items.length : 0}
						disabled={loadingCustomer}
						hiddenInfos={true}
						onClick={() => {
							setMsgLoading('Carregando seus dados...');
							setOpenLoading(true);
							submitCustomer();
						}}
					/>
				)}

				{page === 2 && (
					<FooterBottomSheet
						btnTxt="Avançar"
						tot={cart.tot ? cart.tot : 0}
						qtd={cart.items.length ? cart.items.length : 0}
						disabled={loading}
						onClick={() => handleAddressValidation()}
						shipping={true}
						shippingTax={shippingTax}
						coupon={coupon}
						couponResponse={couponResponse}
						openCoupon={openCoupon}
						cart={cart}
					/>
				)}

				{page === 3 && (
					<FooterBottomSheet
						btnTxt={`${method === '6' ? 'Gerar código para pagamento' : 'Fazer o pedido'}`}
						tot={cart.tot ? cart.tot : 0}
						qtd={cart.items.length ? cart.items.length : 0}
						disabled={loading}
						shipping={true}
						onClick={handlePaymentValidation}
						shippingTax={shippingTax}
						coupon={coupon}
						couponResponse={couponResponse}
						openCoupon={openCoupon}
						cart={cart}
					/>
				)}

				<ModalLoading
					open={openLoading}
					// txt={
					//   method === 'card'
					//     ? 'Processando pagamento, aguarde por favor...'
					//     : 'Gerando código PIX, aguarde por favor...'
					// }
					txt={msgLoading}
				/>
				<ModalCoupon open={openCoupon} setOpen={setOpenCoupon} coupon={coupon} setCoupon={setCoupon} />
				<ModalSuccess open={openSuccess} title={titleSuccess} onClick={handleSuccess} />
				<ModalError open={showSnack} setOpen={setShowSnack} errorMsg={snackMsg} />
				<RegisterAddressPage editAddress={editAddress} open={openRegisterAddress} setOpen={setOpenRegisterAddress} />
			</Dialog>
		</>
	);
}
