import { Button, Dialog, DialogContent, Slide, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import HeaderBottomSheet from '@/components/HeaderBottomSheet';
import CommonMask from '@/helpers/masks';
import { SearchOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { GetAddress, GetCustomerData, PostAddress } from '@/services/delivery/user';
import { RegisterAddressRequest } from '@/models/delivery/registerAddress.request';
import CommonClears from '@/helpers/clears/common.clear';
import { AddressModel } from '@/models/delivery/address.model';
import ModalLoading from '@/components/ModalLoading';
import ModalError from '@/components/ModalError';
import CommonValidators from '@/helpers/validators/common.validators';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

type AddressType = {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	editAddress: AddressModel | undefined;
};

export default function RegisterAddressPage({ open, setOpen, editAddress }: AddressType) {
	const dispatch = useAppDispatch();
	const [search, setSearch] = useState('');
	const [showForm, setShowForm] = useState(false);
	const [openLoading, setOpenLoading] = useState(false);
	const [msgLoading, setMsgLoading] = useState('');
	const [openError, setOpenError] = useState(false);
	const [msgError, setMsgError] = useState('');
	const { customer, address, registerAddress, requestError } = useAppSelector(state => state.userDelivery);
	const [form, setForm] = useState<RegisterAddressRequest>({
		cliente_id: customer?.conteudo.id || 0,
		cep: '',
		endereco: '',
		numero: '',
		complemento: '',
		bairro: '',
		cidade: '',
		estado: '',
		principal: false,
	});
	const [error, setError] = useState({
		cep: '',
		endereco: '',
		numero: '',
		bairro: '',
		cidade: '',
		estado: '',
	});

	function handleChange(value: string | number, fieldName: string) {
		setForm({ ...form, [fieldName]: value });
	}

	async function submitAddress() {
		const cepValidator = CommonValidators.isCepValid(form.cep);
		const addressValidator = CommonValidators.isEmptyField(form.endereco, form.endereco);
		const numberValidator = CommonValidators.isEmptyField(form.numero, form.numero);
		const neighborhoodValidator = CommonValidators.isEmptyField(form.bairro, form.bairro);
		const cityValidator = CommonValidators.isEmptyField(form.cidade, form.cidade);
		const stateValidator = CommonValidators.isEmptyField(form.estado, form.estado);

		let errorData = [];

		if (!cepValidator.status) {
			errorData.push('CEP');
		}

		if (!addressValidator.status) {
			errorData.push('endereço');
		}

		if (!numberValidator.status) {
			errorData.push('número');
		}

		if (!neighborhoodValidator.status) {
			errorData.push('bairro');
		}

		if (!cityValidator.status) {
			errorData.push('cidade');
		}

		if (!stateValidator.status) {
			errorData.push('estado');
		}

		setError({
			cep: cepValidator.error,
			endereco: addressValidator.error,
			numero: numberValidator.error,
			bairro: neighborhoodValidator.error,
			cidade: cityValidator.error,
			estado: stateValidator.error,
		});

		if (errorData.length > 0) {
			setMsgError(`Por favor, insira os dados (${errorData.join(', ')}) corretamente!`);
			setOpenError(true);
		} else {
			setMsgLoading(`${editAddress ? 'Editando' : 'Salvando novo'} endereço...`);
			setOpenLoading(true);

			await dispatch(
				PostAddress({
					request: form,
					editAddressId: editAddress?.id || undefined,
				}),
			);

			await dispatch(
				GetCustomerData({
					cpf: customer?.conteudo.cpf || '',
					fone: customer?.conteudo.fone || '',
					nome: '',
				}),
			);
		}
	}

	async function getAddress({ cep, lat, long }: { cep?: string; lat?: string; long?: string }) {
		await dispatch(GetAddress({ cep: cep || '', latitude: lat || '', longitude: long || '' }));
	}

	function onDismiss() {
		setOpenLoading(false);
		setOpen(false);
		setShowForm(false);
		setForm({
			...form,
			bairro: '',
			cep: '',
			cidade: '',
			complemento: '',
			endereco: '',
			estado: '',
			numero: '',
			principal: false,
		});
		setSearch('');
	}

	useEffect(() => {
		if (address) {
			setOpenLoading(false);
			if (address.conteudo) {
				const { bairro, cep, cidade, endereco, estado } = address.conteudo;
				setForm({
					...form,
					bairro,
					cep,
					cidade,
					endereco,
					estado,
				});
			}
		}
	}, [address]);

	useEffect(() => {
		if (editAddress) {
			const { bairro, cidade, endereco, estado, numero, complemento, cep } = editAddress;
			setForm({
				...form,
				bairro,
				cep,
				cidade,
				endereco,
				estado,
				numero,
				complemento,
			});
		}
	}, [editAddress]);

	useEffect(() => {
		if (customer?.conteudo) {
			setForm({
				...form,
				cliente_id: customer.conteudo.id,
			});
		}
	}, [customer]);

	useEffect(() => {
		if (registerAddress?.retorno) {
			setOpenLoading(false);
			(async () => {
				await dispatch(
					GetCustomerData({
						cpf: customer?.conteudo.cpf || '',
						fone: '',
						nome: '',
					}),
				);
				onDismiss();
			})();
		}
	}, [registerAddress]);

	useEffect(() => {
		setOpenLoading(false);
		if (requestError) {
			setMsgError(requestError);
			setOpenError(true);
		} else {
			setOpenError(false);
		}
	}, [requestError]);

	return (
		<Dialog fullScreen open={open} onClose={onDismiss} TransitionComponent={Transition} scroll="paper" className="customDialog" disableScrollLock id="registerAddress">
			<HeaderBottomSheet title="Novo endereço" onDismiss={onDismiss} />
			<DialogContent>
				{(address && showForm) || editAddress ? (
					<div className="container">
						<TextField
							fullWidth
							className="field"
							label="CEP"
							error={!!error.cep}
							helperText={error.cep}
							required
							value={CommonMask.cep(form.cep)}
							variant="outlined"
							onChange={event => {
								if (event.target.value.length < 10) {
									handleChange(CommonClears.clearCEP(event.currentTarget.value), 'cep');
								}
							}}
							inputMode="numeric"
							inputProps={{
								inputMode: 'numeric',
								pattern: '[0-9]*',
							}}
						/>

						<TextField
							fullWidth
							className="field"
							label="Endereço"
							error={!!error.endereco}
							helperText={error.endereco}
							required
							value={form.endereco}
							variant="outlined"
							onChange={event => {
								const onlyValidChars = event.currentTarget.value.replace(/[^a-zA-Z0-9\s]/g, '');
								handleChange(onlyValidChars, 'endereco');
							}}
						/>
						<TextField
							fullWidth
							className="field"
							label="Número"
							error={!!error.numero}
							helperText={error.numero}
							required
							value={form.numero}
							variant="outlined"
							inputMode="numeric"
							inputProps={{
								inputMode: 'numeric',
								pattern: '[0-9]*',
							}}
							onChange={event => {
								const onlyValidChars = event.currentTarget.value.replace(/[^0-9]/g, '');
								handleChange(onlyValidChars, 'numero');
							}}
						/>
						<TextField
							fullWidth
							className="field"
							label="Complemento"
							value={form.complemento}
							variant="outlined"
							onChange={event => {
								const onlyValidChars = event.currentTarget.value.replace(/[^a-zA-Z0-9\s]/g, '');
								handleChange(onlyValidChars, 'complemento');
							}}
						/>
						<TextField
							fullWidth
							className="field"
							required
							label="Bairro"
							error={!!error.bairro}
							helperText={error.bairro}
							value={form.bairro}
							variant="outlined"
							onChange={event => {
								const onlyValidChars = event.currentTarget.value.replace(/[^a-zA-Z0-9\s]/g, '');
								handleChange(onlyValidChars, 'bairro');
							}}
						/>
						<TextField
							fullWidth
							className="field"
							required
							label="Cidade"
							error={!!error.cidade}
							helperText={error.cidade}
							value={form.cidade}
							variant="outlined"
							onChange={event => {
								const onlyValidChars = event.currentTarget.value.replace(/[^a-zA-Z0-\s]/g, '');
								handleChange(onlyValidChars, 'cidade');
							}}
						/>
						<TextField
							fullWidth
							className="field"
							required
							label="Estado"
							error={!!error.estado}
							helperText={error.estado}
							value={form.estado}
							variant="outlined"
							onChange={event => {
								const onlyValidChars = event.currentTarget.value.replace(/[^a-zA-Z0-\s]/g, '');
								handleChange(onlyValidChars, 'estado');
							}}
						/>
					</div>
				) : (
					<div className="container">
						<div className="blockSearch">
							<TextField
								fullWidth
								label="Insira o cep do endereço..."
								value={CommonMask.cep(search)}
								variant="outlined"
								onChange={event => {
									if (event.target.value.length < 10) {
										setSearch(event.currentTarget.value);
									}
								}}
								onSubmit={() => {
									setMsgLoading('Buscando endereço...');
									setOpenLoading(true);
									getAddress({ cep: CommonClears.clearCEP(search) });
									setShowForm(true);
								}}
								inputMode="numeric"
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
							/>
							<Button
								className="btnSearch"
								onClick={() => {
									setMsgLoading('Buscando endereço...');
									setOpenLoading(true);
									getAddress({ cep: CommonClears.clearCEP(search) });
									setShowForm(true);
								}}>
								<SearchOutlined />
							</Button>
						</div>
						<div className="findCep">
							<p className="findCepTxt">Não sabe seu CEP?</p>
							<a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank" className="findCepLink">
								Clique aqui
							</a>
						</div>
						<Button
							variant="contained"
							className="getLocation"
							onClick={() => {
								setMsgLoading('Buscando endereço...');
								setOpenLoading(true);
								navigator.geolocation.getCurrentPosition(function (position) {
									getAddress({
										lat: position.coords.latitude.toString(),
										long: position.coords.longitude.toString(),
									});
									setShowForm(true);
								});
							}}>
							USAR MINHA LOCALIZAÇÃO ATUAL
						</Button>
					</div>
				)}
			</DialogContent>
			<div className="p-4">
				<Button
					variant="contained"
					className="btnSave"
					style={{
						backgroundColor: 'var(--main-color)',
					}}
					onClick={submitAddress}>
					Salvar
				</Button>
			</div>
			<ModalLoading open={openLoading} txt={msgLoading} />
			<ModalError open={openError} setOpen={setOpenError} errorMsg={msgError} />
		</Dialog>
	);
}
